@import url(~antd/dist/antd.css);
.header {
  background: white;
  padding: 0 16px;
  height: auto;
  box-shadow: 0 -3px 13px #aaa; }
  .header .logo {
    max-width: 262px;
    padding: 25px 0; }
    @media (max-width: 576px) {
      .header .logo {
        max-width: 220px; } }
    @media (min-width: 576px) {
      .header .logo {
        max-width: 220px; } }
  .header .nav {
    border: 0; }
    @media (max-width: 576px) {
      .header .nav {
        display: none; } }
    @media (min-width: 576px) {
      .header .nav {
        display: none; } }
    @media (min-width: 768px) {
      .header .nav {
        display: none; } }
    @media (min-width: 992px) {
      .header .nav {
        display: block; } }
    .header .nav .ant-menu-item, .header .nav .ant-menu-submenu {
      font-size: 16px;
      color: #333;
      border: 0; }
      .header .nav .ant-menu-item:hover, .header .nav .ant-menu-submenu:hover {
        border: 0; }
  .header a {
    color: #333; }
  .header .light-nav {
    float: right; }
    @media (max-width: 576px) {
      .header .light-nav {
        display: none; } }
    @media (min-width: 576px) {
      .header .light-nav {
        display: none; } }
    @media (min-width: 768px) {
      .header .light-nav {
        display: none; } }
    @media (min-width: 992px) {
      .header .light-nav {
        display: flex; } }
  @media (max-width: 576px) {
    .header .sm-menu {
      display: block; }
      .header .sm-menu .ant-btn {
        display: block;
        float: right;
        font-size: 20px;
        color: #FBC600; } }
  @media (min-width: 576px) {
    .header .sm-menu {
      display: block; }
      .header .sm-menu .ant-btn {
        display: block;
        float: right;
        font-size: 20px;
        color: #FBC600; } }
  @media (min-width: 768px) {
    .header .sm-menu {
      display: block; }
      .header .sm-menu .ant-btn {
        display: block;
        float: right;
        font-size: 20px;
        color: #FBC600; } }
  @media (min-width: 992px) {
    .header .sm-menu {
      display: none; } }

.serviceIcon {
  width: 28px;
  filter: drop-shadow(1px 1px 1px #ccc); }

.footer {
  background-color: #FBC600; }
  @media (min-width: 576px) {
    .footer .link-section {
      padding: 0px; } }
  @media (min-width: 768px) {
    .footer .link-section {
      padding: 16px; } }
  @media (min-width: 992px) {
    .footer .link-section {
      padding: 16px; } }
  @media (min-width: 1200px) {
    .footer .link-section {
      padding: 80px  100px; } }
  .footer .link-section h3 {
    color: #333;
    font-size: 18px;
    padding: 5px 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2); }
  .footer .link-section a {
    display: block;
    color: #222;
    font-size: 15px;
    line-height: 2.5;
    opacity: .8; }

.copyright {
  background: #333;
  text-align: center;
  color: white;
  padding: 35px; }
  .copyright .footer-link {
    font-size: small;
    opacity: .5; }
    .copyright .footer-link a {
      color: white; }
      .copyright .footer-link a:hover {
        color: #FBC600; }
